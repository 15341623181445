.ant-modal-body {
  padding: 5px 17px;
}

.ant-comment-inner {
  padding: 7px 0;
}

.ant-row {
  margin-top: 13px;
}

.ant-comment-content-author-name a {
  font-size: 13px;
}
.ant-tag {
  margin-left: 8px;
}

.order-view-modal .modal-body {
  padding: 0 23px 17px 23px !important;
  background: white;
}

.order-view-modal .modal-header {
  background: #ffa01cfa;
}
