.btn1 {
    text-align: center;
    margin-bottom: 30px;
}

.text-error-two {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
}

.error-text {
    text-align: center;
    padding: 2px;
    /* font-family: Cursive; */
    font-size: 16px;
}

.error500 {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    text-decoration: none;
    padding: 10px 25px;
    background: #f8921f;
    color: #fff;
    border-radius: 4px;
}

.error500:hover {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    text-decoration: none;
    padding: 10px 25px;
    background: #f8921f;
    color: #fff;
    border-radius: 4px;
}

.page_404 {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}

.image-set-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


#zoom-an {
    animation-name: flow;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    position: relative;

}