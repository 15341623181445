@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-bg {
  background-image: url(../image/background-dark-1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100Vh;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #fafafa;
  overflow-x: hidden;
  animation: slideInFromLeft 0.6s !important;

}

body.modal-open {
  padding: 0px !important;
  overflow-y: auto !important;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-y: auto !important;
  padding-right: 0 !important;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0.3;

  }

  100% {
    opacity: 1;

  }
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #727272;
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.nav-color.active {
  font-weight: 500;
  color: #f8921f !important;
}

.nav-color {
  font-weight: 500;
}

.navbar-brand {
  padding-left: 50px;
}

.margin-l {
  padding-right: 100px;
}

.nav-item {
  padding-right: 0px;
}

.login-tab {
  margin: 0px 10px;
  width: 45%;
}


.hamburger .line {
  width: 33px;
  height: 4px;
  background-color: #3d3d3d;
  display: block;
  margin: 4px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(9px) rotate(39deg);
  -ms-transform: translateY(9px) rotate(39deg);
  -o-transform: translateY(9px) rotate(39deg);
  transform: translateY(9px) rotate(39deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-7px) rotate(-42deg);
  -ms-transform: translateY(-7px) rotate(-42deg);
  -o-transform: translateY(-7px) rotate(-42deg);
  transform: translateY(-7px) rotate(-42deg);
}

.nav-bar-color {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 16%);
  padding: 15px 5px;
  position: sticky;
  top: 0;
  z-index: 999;
}

#nav-item-h {
  padding-left: 40px;
}

/* section-1  */

.an {
  animation-name: flo;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: relative;



}

@keyframes flo {
  50% {
    transform: translateY(10px);
  }
}




.sec-1 {
  position: relative;
  /* margin-bottom: 40px; */
  padding: 145px 0px 145px 0px;
  background-image: url('../image/main-bg-2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.inner-div {
  padding: 60px 30px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 3px 14px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.heading-1 {
  text-align: center;
  color: #000;
  font-weight: 800;
  line-height: 45px;
  padding: 5px 0px;
}

.box-inset {
  box-shadow: 0px 0px 2px 1px inset #bbb !important;
}

.searching-heading {
  text-align: center;
  color: #f8921f;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 0px;
  font-size: 35px;
}

.para-1 {
  text-align: center;
  color: #272626;
  padding: 5px 0px;
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 0;
}

/* .search-box{
    padding: 0px 43px !important; 
    margin-left: 16px !important; 
  } */

/* .search-box ::placeholder{
    font-size: 19px;
    font-weight: 500;
  }
     */


/* .search-box {
         width: 450px; 
        position: relative;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 5px 0px;
      } */

/* .search-input {
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        padding: 15px 45px 15px 15px;
        background-color: #fafafa;
        color: #283f4a;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border: 1px solid #eee;
        border:none;
        outline:none;
      }
      
    
      
      .search-btn {
        background-color: transparent;
        font-size: 18px;
        padding: 6px 9px;
        margin-left:-45px;
        border:none;
        color: #6c6c6c;
        transition: all .4s;
        z-index: 10;
      }
      
      .search-btn:hover {
        transform: scale(1.2);
        cursor: pointer;
        color: black;
      }
      
      .search-btn:focus {
        outline:none;
        color:black;
      }
       */

.search-box {
  /* padding: 0px 43px !important; */
  /* margin-left: 16px !important; */
}

.search-box ::placeholder {
  font-size: 19px;
  font-weight: 500;
}



.search-box {
  /* width: 450px; */
  position: relative;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 5px 0px;
}

.search-input {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding: 13px 45px 13px 25px;
  background-color: #fafafa;
  color: #283f4a;
  border-radius: 6px;
  transition: all .4s;
  border: none;
  box-shadow: 0 1px 0px #b8c6db;
}

.search-input:focus {
  border: none;
  outline: none;
  box-shadow: 0 1px 0px #b8c6db;
  -moz-box-shadow: 0 1px 0px #b8c6db;
  -webkit-box-shadow: 0 1px 0px #b8c6db;
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding: 6px 9px;
  margin-left: -45px;
  border: none;
  color: #6c6c6c;
  transition: all .4s;
  z-index: 10;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}

.search-btn:focus {
  outline: none;
  color: black;
}



#w {
  display: block;
  width: 100%;
  margin: 0 auto;

}



#searchfield {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 35px;
}

#searchfield form {
  display: inline-block;
  /* background: #eeefed; */
  padding: 0;
  margin: 0;
  padding: 5px;
  border-radius: 3px;
  margin: 5px 0 0 0;
  width: 500px;
}

#searchfield form .biginput {
  width: 100%;
  height: 40px;
  padding: 0 10px 0 10px;
  background-color: #fff;
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 3px;
  color: #aeaeae;
  font-weight: normal;
  font-size: 15px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#searchfield form .biginput:focus {
  border: none;
  outline: none;
}

.flatbtn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  outline: 0;
  border: 0;
  color: #f3faef;
  text-decoration: none;
  background-color: #6bb642;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  font-size: 1.2em;
  font-weight: bold;
  padding: 12px 22px 12px 22px;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 rgba(15, 15, 15, 0.3);
  -moz-box-shadow: 0 1px 0 rgba(15, 15, 15, 0.3);
  box-shadow: 0 1px 0 rgba(15, 15, 15, 0.3);
}

.flatbtn:hover {
  color: #fff;
  background-color: #73c437;
}

.flatbtn:active {
  -webkit-box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1);
}

/* .autocomplete-suggestions {
  border: 1px solid #999;
  background: #fff;
  cursor: default;
  overflow: auto;
}

.autocomplete-suggestion {
  padding: 10px 5px;
  font-size: 1.2em;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background: #f0f0f0;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399ff;
}


.autocomplete-suggestions {
  position: absolute;
  display: none;
  width: 490px !important;
  top: 363.5px;
  left: 517px;
  max-height: 300px;
  z-index: 9999;
} */

.fa-search {

  top: -31px;
  left: 218px;
  position: relative;
}

/* .autocomplete-suggestion {
  padding: 10px 5px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
} */




/* section-1 */


/* section-2  */

.sec-2 {
  /* margin-bottom:40px; */
  background-color: #fafafa;
}

.traveling {
  text-align: center;
}

.traveling-title {
  font-weight: 800;
  color: #f8921f;
  font-size: 26px;
}

.traveling-para {
  font-size: 19px;
  color: black;
}

.card-img-top {
  height: 200px;
  border-radius: 10px;
  background: #fbfbfb;
  padding: 5px;
  margin-bottom: 0px;
  object-fit: cover;
}

.card-img-top-details {
  min-height: 170PX;
  max-height: 250PX;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background: #fbfbfb;
  padding: 5px;
  margin-bottom: 15px;
}

.card-text-1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.ul-margin {
  margin-bottom: 0px;
  padding: 0;
}

.text-name {
  width: 70%;
  height: auto;
}

.text-icon {
  width: 25%;
  height: auto;
  text-align: end;


}

.para-footer-all {
  color: white;
  text-align: center;
  font-weight: 300;
}

.shop {
  padding: 0px 5px;
  transition: all 0.4s ease-in;
  color: white;
}

.price-text {

  position: absolute;
  bottom: 15px;

}

.shop a {
  text-decoration: none;
}

.shop-link-t a {
  text-decoration: none;
  color: #f8921f;
}

.bahamas {
  text-transform: capitalize;
  list-style: none;
  color: #f8921f;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.bahamas a {
  font-size: 18px;
  color: #283f4a;
  text-decoration: none;
  align-items: start;
}

.check-out {
  display: flex;
  justify-content: space-between;
}

.nrth-amrica {
  list-style: none;
}

span.day-5gb strong {
  color: #f8921f;
  font-size: 19px;
  line-height: 26px;
}

center .card-img-top {
  height: auto;
  /* object-fit: unset; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.nrth-amrica a {
  font-size: 13px;
  color: #727272;
  font-weight: 400;
  text-decoration: none;
}

.card-text-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.card-text-5 {
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.day-5gb {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.dolor {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  white-space: nowrap;
}

.btn-product-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px 0px; */
}

.work-box img {
  width: 120px;
  margin-bottom: 10px;
}

.work-box {
  text-align: center;
}

.work-box h5 {
  color: #f8921f;
  font-weight: 600;
  margin-bottom: 5px;
}

.work-box p {
  color: black;
  font-size: 14px;
}

.btn-more-product {
  text-decoration: none;
  padding: 4px 4px;

  outline: none;
  color: #2d2a2a !important;
  font-weight: 600;
  position: relative;
  transition: all .35s;
  border-radius: 5px;

}

.btn-more-product:hover {
  background-color: #f8931d;
  color: #ffffff !important;
}

.btn-more-product-check-out {
  text-decoration: none;
  padding: 4px 4px;
  outline: none;
  color: #2d2a2a !important;
  font-weight: 600;
  position: relative;
  transition: all .35s;
  border-radius: 5px;

}






.btn-more-product span {
  position: relative;
  z-index: 2;
}

.btn-more-product:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #f8931d;
  transition: all .35s;
  border-radius: 5px;
}

.btn-more-product:hover {
  color: #fff;
}

.btn-more-product:hover:after {
  width: 100%;
}


.fa-shopping-cart {
  color: #f8921f;
}


/* section-2  */

/* section-3  */
.sec-3 {
  background-color: #fff;
  padding-top: 35px;
}

.slider {
  /* margin-bottom: 20px; */
  position: relative;
}

.slider .owl-item.active.center .slider-card {
  transform: scale(1.15);
  opacity: 1;
  background-color: #fefefe;

}

.fa-star,
.fa-flag {
  color: goldenrod;
}

.slider-card {
  width: auto;
  height: auto;
  background-color: #fefefe;
  /* margin: 50px 0px 90px 0px; */
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transform: scale(0.9);
  /* opacity: 0.5; */
  transition: all 0.3s;
}

.owl-nav .owl-next {
  position: absolute;
  top: calc(50% - 25px);
  right: 0;
  opacity: 1;
  font-size: 30px !important;
  z-index: 1;
}

.owl-nav .owl-prev {
  position: absolute;
  top: calc(50% - 35px);
  left: 0;
  opacity: 1;
  font-size: 30px !important;
  z-index: 1;
}

.header-carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 13px;
}

.body-carousel {
  padding: 20px 20px;
}

.heading-2 {
  text-align: start;
  color: #f8921f;
  font-weight: 700;
  margin-bottom: 20px;
}

.heading-carousel-emap {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px;
}

/* section-3  */

/* section-4  */
.heading-3 {
  text-align: start;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
}

.sec-4 {
  background-color: #f3f6f9;
  padding: 45px 0px;
}


.btn-more-divices {
  display: block;
  text-decoration: none;
  padding: 7px 70px;
  outline: none;
  color: #2d2a2a !important;
  font-weight: 600;
  border: 1px solid #727272;
  position: relative;
  transition: all .35s;

  background: #f8fafb;
  border-radius: 5px;

}

.btn-more-divices:hover {
  background-color: #f8921f;
  color: #ffffff !important;
  border: 1px solid #f8921f;
}


.btn-more-divices span {
  position: relative;
  z-index: 2;
}

.btn-more-divices:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #f8921f;
  transition: all .35s;
}

.btn-more-divices:hover {
  color: #fff;
}

.btn-more-divices:hover:after {
  width: 100%;
}





.btn-product-box-dives {
  display: flex;
  align-items: center;
  justify-content: start;
}

.img-divces-pabillty {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* section-4  */
/* section-6  */
section.sec-6 {
  background: #f8921f;
  padding: 45px 0px;
}

.most-text {
  color: black;
  font-weight: 500;
  font-style: italic;
}

/* section-5  */

.sec-5 {
  background-color: #2d2d2d;

}



.footer-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo-footer {
  width: 250px;
  height: 60px;
  text-align: center;
}

.flex-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-icon li {
  list-style: none;
  margin: 0px 13px 0px 5px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  /* background-color: #fff; */
  box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
}


.flex-icon li a img {
  padding: 8px 0px 0px 8px;
}

.logo-footer {
  margin-bottom: 15px;
}

.text-footer {
  margin-bottom: 15px;
  font-weight: 500;
  color: #727272;
}

.social-media-icons {
  margin-bottom: 30px;
}

/* section-5 */
/* shop  */
.search-destination {
  padding: 0px 95px;
}

.home-sec-icon {
  padding: 20px 50px;
}

.shop-a {
  text-decoration: none;
  color: #f8921f;
}


.sec-shop-1 {
  background-color: #ffffff;
}

.sec-shop-2 {
  background-color: #f8921f3b;
  padding: 45px 0px;
}

.sec-shop-3 {
  background-color: #ffffff;
  padding-bottom: 50px;
  padding-top: 20px;
}

#pills-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link-btn {
  /* border: 1px solid grey !important; */
  color: #252525;
  padding: 6px 14px;
  margin-bottom: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ffffff;
  background-color: #f8921f;
  /* border: 1px solid #f8921f !important ; */
  border: none;
}

.logo-footer a {
  text-decoration: none;
  color: gray;
  font-size: 33px;
  font-weight: 600;
}

/* shop  */


/* section-plan  */
.plan-sec-2 {
  background-color: #f8921f3b;
  padding: 20px 5px;
  
  /* height: 1365px; */


}
/* .heit-set{

  height: calc(100vh - -785px) !important;
} */

.contain-plan-sec {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px 10px;

}

.details-card-1 {
  /* width: 70%; */
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  /* border-radius: 10px; */
  padding: 0px 20px;
}

.details-card {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  background-color: #f44b57;
  padding-top: 1.5em;
  /* padding-bottom: 5em; */
  padding-bottom: 1em;
}

/* .details-para-1 {
  padding-top: 20px;
} */

.details-para-3 {
  font-weight: 700;
  color: #000000;
  font-size: 16px;
}

.cov-list ul li {
  font-size: 13px;

  text-align: justify;
}

.details-para-4 {
  padding-top: 42px;
  line-height: 29px;
}

.cov-list ul {
  margin-bottom: 0;
}





.btn-more-details {

  text-decoration: none;
  padding: 7px 70px;
  outline: none;
  color: #2d2a2a !important;
  font-weight: 600;
  border: 1px solid #727272;
  position: relative;
  transition: all .35s;

  background: #f8fafb;
  border-radius: 5px;

}

.btn-more-details {
  background-color: #f8921f;
  color: #ffffff !important;
  border: 1px solid #f8921f;
}


.btn-more-details span {
  position: relative;
  z-index: 2;
}

.btn-more-details:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #bc6a0c;
  transition: all .35s;
}

.btn-more-details:hover {
  color: #fff;
}

.btn-more-details:hover:after {
  width: 100%;
}

.btn-more-details-disable {

  text-decoration: none;
  padding: 7px 70px;
  outline: none;
  color: #2d2a2a !important;
  font-weight: 600;
  border: 1px solid #727272;
  position: relative;
  transition: all .35s;

  background: #f8fafb;
  border-radius: 5px;

}

.btn-more-details-disable {
  background-color: #cad5da;
  color: #ffffff !important;
  border: transparent;
}


.btn-more-details-disable span {
  position: relative;
  z-index: 2;
}

.btn-more-details-disable:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  /* background-color: #018ac0; */
  /* transition: all .35s; */
}

.btn-more-details-disable :hover {
  color: #fff;
}

.btn-more-details-disable:hover:after {
  width: 100%;
}



.network {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 19px;
  border-top: 1px solid #72727217;
  height: 33px;
}

.network-1 {
  padding: 0px 20px;
}

.p-set {
  padding-top: 13px;
  font-size: 14px;
  font-weight: 500;
  color: #151414;
}

.p-set-1 {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #151414;
}


.addtional-main-div {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  background-color: #ff9d0e;
  /* padding: 10px 10px; */
  margin: 0 0 20px 0;
}

.aditional-infrmtion h6 {
  color: #151414;
  padding: 0px 13px;
}

.aditional-infrmtion {
  /* padding-top: 30px; */
}

/* .carousel-control-prev-icon {
    background-image: url("/assets/image/left-arrow.png");
  }

  .carousel-control-next-icon {
    background-image: url("/assets/image/right-arrow.png");
} */


.carousel-control-prev {
  left: -50px !important;
}

.carousel-control-next {
  right: -50px !important;
}

.top-up-package {
  text-align: start;
  color: #f9f3f3;
  padding: 0px 20px;
}

.dta-pack {
  color: #f9f3f3;
  padding-top: 0px;
  padding-left: 20px;
}



.plan-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 46px;
  height: 39px;
  border-top: 1px solid #ffffff14;
  padding: 0px 20px;
}

.plan-type span {
  color: #f9f3f3;
}

/* sec tion-plan  */

/* login  */


.login-sec-1 {
  text-align: center;
  display: flex;
  align-items: center;
}

.login-sec-1 a {
  text-decoration: none;
  color: #727272;
}


.form-tab {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.form-tab-1 {
  /* box-shadow: 0px 6px 12px rgb(0 0 0 / 16%); */
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 2px 2px 8px rgb(0 0 0 / 35%);
}

#pills-home1 {
  width: 100%;
  /* animation: zoom-in-zoom-out 0.6s ease; */
  /* -webkit-animation: fade-in-fwd 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-fwd 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */

  -webkit-animation: swing-in-top-fwd 0.7s linear both;
  animation: swing-in-top-fwd 0.7s linear both;

}


.login-title {
  padding: 2px 10px;

}

.login-title h2 {
  color: #f8921f;
  font-weight: 600;
}

.login-title p {
  text-align: center;
  color: #000;
}

.login-title h4 {
  color: #000;
}

.para-sign {
  font-size: 13px;
}

.form-1 {
  padding: 0px 10px;
}

.form-check {
  padding: 0px 0px;
  text-align: right;
}

.form-check-label {
  padding-right: 10px;
  color: #727272;
  /* font-size: 15px; */
  font-size: 15px;
  padding-top: 7px;
  /* 15 12 23 */
}

label.form-check-label a {
  color: #727272;
}

.form-check-input {
  width: 2em;
  height: 1.6em;
  margin-right: 10px;
  cursor: pointer;
}

.form-check a {
  text-decoration: none;
  color: #727272;
}

.login-btn {
  text-decoration: none;
  padding: 10px 0px;
  border: none;
  outline: none;
  color: #ffffff;
  transition: all 0.6s;
  background: #f8921f;
  border-radius: 5px;
  width: 100%;
}


.nav-pills .nav-link {
  background: 0 0;
  border: 1px solid gray;
  border-radius: var(--bs-nav-pills-border-radius);
}

.form-control:focus {
  color: #212529;
  background-color: #ebe8e8;
  border: 1px solid #f8921f !important;
  outline: 0;
  box-shadow: none;
}

.cov-list p {
  margin: 0;
}

/* login  */
.pro-sticky {
  height: 100%;
  min-height: 200px;
  overflow: visible;
  position: -webkit-sticky;
  position: sticky;
  top: 12%;
}

.plan-det-card .day-5gb {
  font-size: 12px;
}

.plan-det-card .india-coverage {
  padding: 2px 10px;
  font-size: 12px;
}

.plan-det-card {
  height: 350px !important;
}

.plan-det-car .bghjHX {
  margin: 0 !important;
}

span.pricing:hover {
  color: white;
}

/* profile  */
.profile-sec-1 {
  background-color: #ffffff;
  padding: 80px 0px;
}

.user-name {
  box-shadow: 0px 2px 12px rgb(0 0 0 / 16%);
  padding: 10px 25px;
  border-radius: 9px;
}

.user-name-text {
  padding: 15px 10px;
}

.user-name-text h5 {
  /* text-align: center; */
  font-weight: 600;
}

#v-pills-messages-tab {
  margin-bottom: 10px;
}

.logout {
  padding: 30px 10px;
}

.logout a {
  text-decoration: none;
  color: #212529;
  font-size: 18px;
  font-weight: 500;
}

.form-group {
  position: relative;
}

.gm-p {
  margin-left: 40px;
}

.accout-information-form {
  padding: 10px 0px;
}

.accout-information-form h5 {
  color: #212529;
}

.mg-b {
  margin-bottom: 0px;
  text-align: start;
  color: #212529;
}

.change {
  padding: 2px 20px;
  outline: none;
  color: #f8921f;
  position: absolute;
  right: 0%;
  top: 16.3%;
  border-radius: 5px;
}





.nav-pills .btn-account-f.active,
.nav-pills .show>.nav-link {
  color: #000000;
  background-color: #ffffff;
  border: none;
  border-left: 3px solid #3d3c3c !important;
  text-align: left;
  border-radius: 0px;
  font-weight: 500;
}

.btn-account-f {
  border: none !important;
  outline: none !important;
}

.order-yet {
  padding: 90px 15px 0px 15px !important;
  /* margin: 0px 0px 0px -52px; */
}

.order-yet h5 {
  font-weight: 700;
  color: #000000;
}

/* profile  */

/* check-out  */
.form-select:focus {
  border-color: #65636369;
  /* outline: 0; */
  box-shadow: none;
}

.start-height {
  height: 100%;
}

.total {
  margin-top: 10px;
  font-weight: 800;
  color: black;
}

.shop-btn {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background-color: #f8921f;
  border-radius: 6px;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  text-align: center;
  padding: 7px 0px;
}

.shop-btn:hover {
  color: #ffffff;
}

.g-pay {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background-color: #f8921f;
  border-radius: 6px;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  text-align: center;
  padding: 7px 0px;
  font-weight: 600;
}

.g-pay:hover {
  color: #ffffff;
}




.check-sign-btn {
  border: none;
  outline: none;
  background-color: #f8921f;
  border-radius: 6px;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  text-align: center;
  width: 100%;
  padding: 0.575rem 0px;
}

.check-sec {
  padding: 30px 15px;
  background-color: #ffffff;
  min-height: 691px;
}


.body-content-text {
  padding: 0px 10px !important;
}

/* check-out  */


/* contact us  */
.text-message {
  padding: 11px 5px 9px 0px;
}

.text-message p {
  color: #696969;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.text-contact-detail h6 {
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 20px;
}

.text-contact-detail ul li {
  list-style: none;
  color: #212529;
}

.text-contact-detail ul {
  padding: 0px 0px 0px 0px;
}

.contact-item {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #727272 !important;
  line-height: 3;
}

/* contact us  */


/* partner  */
.sec-patner {
  background-color: #f8921f3b;
}

.img-material {
  padding: 40px 1px;

}

#laptopanimate {
  transform: translatey(0px);
  animation: laptops 25s ease-in-out infinite;
}

@keyframes laptops {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-8px);
  }

  100% {
    transform: translatey(0px);
  }
}


.keepgo-h {
  padding: 60px 46px;
}

.keepgo-sim {
  color: #f8921f;
  font-weight: 800;
}

.create-sim-para {
  padding: 12px 82px 15px 0px;
  color: #000000;
  margin-bottom: 2em;
}





.become-btn {
  text-decoration: none;
  padding: 7px 70px;
  outline: none;
  color: #000 !important;
  font-weight: 600;
  border: 1px solid #000;
  position: relative;
  transition: all .35s;
  background: #f9e2c7;
  border-radius: 5px;

}

.become-btn:hover {
  background-color: #f8921f;
  color: #ffffff !important;
  border: 1px solid #f8921f;
}


.become-btn span {
  position: relative;
  z-index: 2;
}

.become-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #f8921f;
  transition: all .35s;
}

.become-btn:hover {
  color: #fff;
}

.become-btn:hover:after {
  width: 100%;
}


p.icons-para-text {
  font-size: 18px !important;
  font-weight: 700;
  color: #f8921f !important;
  text-align: center !important;
}

.icon-text p {
  font-size: 14px;
  text-align: justify;
  color: #000;
}

.card-icon:hover p {
  color: white;
}



.sec-patner-2 {
  text-align: center;
  background-color: #ffffff;
  padding: 30px 0px;
}

.card-icon {
  position: relative;
  /* width: 298px;*/
  height: 300px;
  padding: 20px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
}


.card-icon:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: #f8921f;

}

.card-icon:hover .icons-para-text {
  color: white !important;
}

.card-icon:hover #img-hovers {
  filter: brightness(10.3);
}

.img-icon {
  padding: 8px 0px 14px 0px;
}

.about-emap-para {
  font-size: 14px;
  color: #727272;
  font-weight: 500;
  padding-left: 25px;
}

:where(.css-dev-only-do-not-override-k7429z).ant-input:hover {
  border-color: #f8921f !important;
}

:where(.css-dev-only-do-not-override-k7429z).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #f8921f;
}

:where(.css-dev-only-do-not-override-k7429z).ant-input:focus,
:where(.css-dev-only-do-not-override-k7429z).ant-input-focused {
  border-color: #f8921f;
}

:where(.css-dev-only-do-not-override-k7429z).ant-input-affix-wrapper:focus,
:where(.css-dev-only-do-not-override-k7429z).ant-input-affix-wrapper-focused {
  border-color: #f8921f;
}

.jion-our-title {
  padding: 17px 228px 18px 20px;
  line-height: 42px;
  font-weight: 700;
  color: #f8921f;
}

.zim-cover-para {
  line-height: 30px;
  padding: 2px 218px 0px 22px;
  font-size: 21px;
  color: #000;
}

.sec-partner-3 {
  background-color: #ffffff;
}

.panel-shadow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #727272;
  box-shadow: hsla(206, 6%, 25%, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.img-contol-panel {
  padding: 15px 8px 13px 9px;
}



.img-contol-panel-1 img {
  width: 100%;
  height: auto;
  padding: 24px 25px 8px 25px;
}

.sec-partner-4 {
  background-color: #ffffff;
  padding: 30px 0px;
}

.control-panel-h5-h {
  padding-left: 0px;
  color: #f8921f;
  font-weight: 600;
}

img.img-fluid.rounded-start {
  width: 100px;
}

.get-para {
  padding-left: 0px;
  font-size: 14px;
  color: black;
}

.control-panel-h5 {
  color: #f8921f;
  font-weight: 600;
}

.model-btn-send {
  padding: 6px 103px;
  background-color: #fafafa;
  border: 1px solid #f8921f;
  border-radius: 5px;
  text-decoration: none;
  color: #f8921f;
  outline: none;
  font-size: 21px;
  margin-top: 7px;
  transition: 0.3s ease-in;
  font-weight: 500;
}

.model-btn-send:hover {
  color: #fff;
  background-color: #3d3d3d;
  border: 1px solid;
}

.contact-sale {
  text-align: center;
}

.contact-sale h5 {
  font-weight: 600;
  font-size: 30px;
  color: #f8921f;
}

.pro-status {
  position: absolute;
  right: 4px;
  bottom: -12px;
  background: #f8921f;
  border-radius: 10px 0px 5px 0px;
  padding: 2px 15px;
  color: white;
  font-size: 14px;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}


.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px 60px 40px 60px !important;
}

button.nav-link {
  width: 100% !important;
  color: black;
}

button.nav-link:hover {
  color: #f8921f;
}

div#pills-profile1 {
  width: 100%;
  /* animation: zoom-in-zoom-out 0.6s ease; */
  /* -webkit-animation: fade-in-fwd 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-fwd 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */

  -webkit-animation: swing-in-top-fwd 0.7s linear both;
  animation: swing-in-top-fwd 0.7s linear both;


}

.login-title h5 {
  font-weight: 700;
  font-size: 24px;
  color: #f8921f;
  margin: 10px 0px;
}

.form-control {
  padding: 0.575rem 0.75rem;
  font-size: 15px;
}

.contain-box {
  box-shadow: 0px 2px 5px rgb(0 0 0 / 16%);
  border-radius: 10px;
  background: white;
  position: relative;
}

hr {
  margin: 0.5rem 0;
}

.dle-text {
  font-size: 25px;
  color: #d30000;
  margin-bottom: 20px;
}

.del-bg {
  background: #d30000;
}

.text-footer ul li {
  display: inline-block;
  padding: 0px 15px;
}

.text-footer ul li a {
  color: #fff;
  text-decoration: none;
}

.cont-text {
  font-size: 21px;
  font-weight: 700;
  color: black;
  margin-bottom: 1.5em;
}

/* li.contact-item svg {
  fill: #727272;
  height: 18px;
  width: 24px;
} */

li.contact-item svg {
  fill: #f8921f;
  height: 30px;
  width: 30px;
  background-color: #f3b36b36;

  padding: 6px 2px;
  border-radius: 100%;
}


.login-btn:hover {
  background: #d26f2d !important;
}

/* partner new css  */

#animate-1 {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {

    transform: translatey(0px);
  }

  50% {

    transform: translatey(-20px);
  }

  100% {

    transform: translatey(0px);
  }
}

.sec-patner-2 h3 {
  font-weight: 700;
  color: #f8921f;
}

.padding-size {
  padding: 12px 0px;
}

.card:hover .control-panel-h5 {
  color: #fff;
}

.card:hover .control-panel-h5-h {
  color: #fff;
}


.size-img {
  border-radius: 6px;
  height: 40px;
}

#parent {
  position: relative;

}

#position-absolute {
  position: absolute;
  right: -20px;
  top: -15px;
  padding: 2px 12px;
  background-color: red;
  color: white;
  border-radius: 100%;
  font-size: 17px;
  font-weight: bold;
}

.card-title {
  color: #f8921f;
  font-weight: 700;
  margin-bottom: 5px;
}


.span-color {
  color: #403f3f;
  font-weight: 500;
}

.billing-address {
  color: #403f3f;
  font-weight: 700;
  margin-bottom: 30px;
}







/* section 4  */



.boxes {

  cursor: pointer;
  transition: all 1s;
  /* lets make it smooth */

}

.move-up:hover {

  transition: all .5s;
  transform: translateY(-10px);
}

.move-down:hover {

  transition: all .5s;
  transform: translateY(10px);
}

.move-right:hover {

  transition: all .5s;
  transform: translateX(10px);
}

.move-left:hover {

  transition: all .5s;
  transform: translateX(-10px);
}

/* section 4 */




* {
  transition: all 0.3s ease-out;
}




.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #f8921f;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card-1-new {
  display: block;
  position: relative;
  height: 210px;
  background-color: #fffcf8;
  border-radius: 4px;
  padding: 9px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card-1-new:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #f8921f;
  height: 32px;
  width: 32px;
  border-radius: 42px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card-1-new:hover:before {
  transform: scale(35);
}

.card-1-new:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card-1-new:hover h3 {
  transition: all 0.3s ease-out;
  color: #fff;
}

.card-1-new:hover #white-img {
  filter: brightness(2.2);
}

.form-select {
  padding: 0.575rem 0.75rem;
}

.modal-content {
  border-bottom: 5px solid #f8921f;
  background-color: #f5f5f5;
}

/* partner new css  */

.card {
  transition: all 0.6s ease-in;
  margin: 5px;
  cursor: pointer;
}

.reg-heading {
  color: #000;
  font-weight: 600;
  margin-left: 25px;
}

.col .card {
  height: 400px;
}

.card:hover {

  transform: translateY(-0.25rem);
  box-shadow: rgba(46, 41, 51, 0.08) 0px 2px 4px, rgba(71, 63, 79, 0.16) 0px 5px 10px;
  transition: .3s ease;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;

}


.card-hide-img:hover .card-hide-img {
  overflow: hidden;
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

/* parlaxe  */

.owl-nav .owl-next {
  position: absolute;
  top: calc(50% - 65px) !important;
  right: -24px !important;
  opacity: 1;
  font-size: 56px !important;
  z-index: 1;
}

.owl-nav .owl-prev {
  position: absolute;
  top: calc(50% - 65px) !important;
  left: -24px !important;
  opacity: 1;
  font-size: 56px !important;
  z-index: 1;
}

.change-1 {
  /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
  border: 2px solid #f8921f;
  margin-top: 10px;
  text-decoration: none;
  font-size: 14px;
}

.how-work {
  color: black;
  font-weight: 600;
}

.change-1:hover {

  color: #f8921f;

}

.autocomplete-responsive {
  position: absolute;
  width: 100%;
  top: 98%;
  left: 0px;
  max-height: 400px;
  z-index: 9999;
  display: block;
  border-radius: 5px;
  box-shadow: grey 2px 2px 2px -1px;
  padding-bottom: 10px;
}

.cards center {
  position: relative;
}

.autocomplete-suggestion {
  padding: 3px 19px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-suggestion:hover {
  background-color: #eee;
}

.autocomplete-suggestions {
  border: 1px solid #ddd;
  background: #fafafa;
  cursor: default;
  overflow: auto;
}

.countries-search-segment {
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .15);
  margin: 9px 20px 9px 19px;
  max-width: 100%;
  text-align: left;
  width: -moz-max-content;
  width: max-content;
}

.countries-search-segment p {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin: 0 auto;
  padding: 7px 20px 6px;
  text-align: center;
}

.segment-country {
  background-color: #F4D03F;
  background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
}

.segment-region {
  background-image: linear-gradient(90deg, #eb1c8a, #940c31);
}

.segment-global {
  background: linear-gradient(90deg, #07053f, #0988a3);
}

.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}




@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

.modal-2 {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content-2 {
  background-color: #fefefe;
  margin: 5% auto 15% auto;
  border: 1px solid #888;
  width: 38% !important;
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: -18px;
  /* bottom: 3px; */
  color: #00a7e9;
  font-size: 35px;
  font-weight: 500;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}

.modal-content1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 0 0 100px;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  border-bottom: 6px solid #f8921f;

}

.modal-content2 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0px 0px 0px 132px !important;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  border-bottom: 6px solid #f8921f;

}

.accordion-button:not(.collapsed) {
  background: #f9e2c7;
  color: #ff9300;
}

.setContainer {
  padding: 15px 25px;
}

.antdInputPassword {
  padding: 11px 14px;
  /* box-shadow: 0px 3px 12px hsl(0deg 0% 0% / 35%); */
}

.antdInput {
  padding: 13px 15px;
}

.antdInputContact {
  border-color: #ced4da;
  padding: 7px 15px;
}

.partnerSendBtn {
  display: flex;
  font-weight: bold;
  justify-content: center;
}

/* Example of custom CSS classes */
.custom-swal-container {

  /* Your styles for the container */
}

.custom-swal-title {
  /* Your styles for the title */

}

.custom-swal-button {
  /* Your styles for the confirm button */
  background-color: #f8921f !important;
}

.custom-popup-class {
  border-bottom: 4px solid #f8921f !important;
  /* Custom styles for the popup */
}

.custom-title-class {
  /* Custom styles for the title */
  color: #f8921f !important;
}

.custom-button-class {
  /* Custom styles for the confirm button */
  background-color: #f8921f !important;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navIcon {
  margin-right: 5px;
}

/* .bqyzQp {
  position: relative;
  margin-bottom: 30px;
} */

/* .bghjHX {
  overflow: hidden;
  margin: 0px 7px 0px 72px !important;
} */
/* .kVtMtA {
  position: absolute;
  top: calc(28% - 17.5px) !important;
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: 5px;
  right: initial;
} */

/* .bhRnqO {
  position: absolute;
  top: calc(32% - 17.5px) !important;
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: initial;
  right: 5px;
} */
.regiondiv {
  border: 1px solid #5a5a5a;
  text-align: center;
  width: auto;
  height: 40px;
  padding: 7px 6px;
  border-radius: 6px;
  font-weight: 500;
  font-family: 'Poppins';
  margin-left: 10px;
  cursor: pointer;
}

.regiondiv:active {
  background-color: #f8921f;
  color: #fff;
}

.bhRnqO {
  position: absolute;
  top: calc(51% - 18.5px);
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s !important;
  transition: opacity 0.25s !important;
  left: initial;
  right: -20px !important;
}

.kVtMtA {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s !important;
  transition: opacity 0.25s !important;
  left: -10px !important;
  right: initial;
}

.active2 {
  background-color: #f8921f;
  color: #fff;
  border: 2px solid #f8921f;
}



/* ---------------------------------------------------- */

.HomepageLoader {
  margin: 0 auto;
  margin-top: 22%;
}

/* check-out-details  */
.card-check-out {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  border-bottom: 4px solid #f8921f;
}

.body-content-text {
  padding-top: 12px !important;
}

#parent {
  position: relative;
  padding-top: 11px !important;
  text-align: center;
  padding-bottom: 5px;
}

/* check-out-details  */

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {

  background-color: #f8921f !important;

}

/* order  */
.order-details {
  width: 100%;
  max-height: 70vh;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden
}

.order-show {
  width: 100%;
  /* height: 115px; */
  background-color: #ffffff;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  padding: 5px 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
  border-bottom: 3px solid #f8921f;
  margin-bottom: 10px;
  position: relative;

}


.img-flag-order {
  color: rgb(55, 53, 53);
}

.order-plan {
  color: rgb(55, 53, 53);
}

.order-details-plan {
  color: rgb(55, 53, 53);
}


.img-flag-order {
  /* border: 1px solid black; */
}

.img-flag-order img {
  width: 131px;
  height: auto;

}

.plan-name {
  font-size: 19px;
  font-weight: 600;
  list-style: none;
  font-family: 'Poppins';
}

.validity {
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  font-family: 'Poppins';
  color: #727272;
}

.price {
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  font-family: 'Poppins';
  color: #727272;
}

.detail-icon-plan {
  font-size: 19px;
  font-weight: 600;
  margin-top: -41px;
  list-style: none;
  cursor: pointer;
}


/* order-details  */


/* image text overlap */

.image-container {
  position: relative;
  display: inline-block;
}

.text-overlayHome {
  position: absolute;
  top: 85%;
  left: 50%;
  color: #47627d;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
  text-transform: capitalize;
}

.text-overlay p {
  margin: 0;
  font-size: 20px;
  color: black;
  /* Text color */
  font-weight: bold;
}

.text-overlayShop {
  position: absolute;
  top: 85%;
  left: 50%;
  color: #47627d;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
  text-transform: capitalize;
}

.sec-partner-4 h3 {
  font-weight: 700;
  color: #f8921f;
  text-align: center;
}

.text-overlayPlanDetails {
  position: absolute;
  bottom: 5%;
  left: 50%;
  color: #47627d;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
  text-transform: capitalize;
  margin: 0;
}

div#parent img {
  height: 270px;
  width: 100%;
}

.text-overlayCheckout {
  position: absolute;
  top: 25px;
  left: 50%;
  color: #47627d;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
  text-transform: capitalize;
}

.resetPasswordPadding {
  padding-top: 1rem !important;
  padding-bottom: 0 !important;
}

/* .forgetPasswordImg {
  width: 250px;
  padding-bottom: 13px;
} */

.resetPasswordImg {
  width: 250px;
  padding-bottom: 13px;
}

.bck-btn {
  width: 50%;
  border: 1px solid #f8921f;
  padding: 7px 0px;
  border-radius: 5px;
  background: none;
  font-size: 15px;
}

.rtr-btn {
  background: #f8921f;
  color: white;
  width: 48%;
  padding: 7px 0px;
  border-radius: 5px;
  border: 1px solid;
  font-size: 15px;
}

.paper-box {
  max-width: 380px;
  margin: 30px auto;
  overflow: hidden;
}

.printer-top {
  z-index: 1;
  border: 6px solid #f8921f;
  height: 6px;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  background: #f8921f;
}

.printer-bottom {
  z-index: 0;
  border: 6px solid #f8921f;
  height: 6px;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  background: #f8921f;
}

.paper-container {
  position: relative;
  overflow: hidden;
  height: 467px;
}

.paper {
  background: #ffffff;
  height: auto;
  position: absolute;
  z-index: 2;
  margin: 0 12px;
  margin-top: -12px;
  animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
  -moz-animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
}

.main-contents {
  margin: 0 12px;
  padding: 24px;
}

.jagged-edge {
  position: relative;
  height: 20px;
  width: 100%;
  margin-top: -1px;
}

/* .jagged-edge:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%);
  background-size: 16px 40px;
  background-position: 0 -20px;
} */


.jagged-edge:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(45deg, transparent 33.333%, #cfcaca 33.333%, #ecdede 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #f2ecec 33.333%, #ffffff 66.667%, transparent 66.667%);
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.error-title {
  font-size: 22px;
  text-align: center;
  color: #ff6347;
  font-weight: bold;
  margin-bottom: 16px;
}

.success-description {
  font-size: 15px;
  line-height: 21px;
  color: #999;
  text-align: center;
  margin-bottom: 24px;
}

.payment-popup {
  text-align: center;
  color: #333;
  font-weight: bold;
}

.payment-popup .order-number-label {
  font-size: 18px;
  margin-bottom: 8px;
  color: #f8921f;
}

.payment-popup .order-number {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  line-height: 40px;
  font-size: 33px;
  padding: 3px 0;
  margin-bottom: 24px;
  color: #f8921f;
}

.order-footer {
  text-align: center;
  line-height: 18px;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
  color: #999;
}

@keyframes print {
  0% {
    transform: translateY(-90%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes print {
  0% {
    -webkit-transform: translateY(-90%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

@-moz-keyframes print {
  0% {
    -moz-transform: translateY(-90%);
  }

  100% {
    -moz-transform: translateY(0%);
  }
}

@-ms-keyframes print {
  0% {
    -ms-transform: translateY(-90%);
  }

  100% {
    -ms-transform: translateY(0%);
  }
}

.success-title {
  font-size: 22px;
  text-align: center;
  color: #209303;
  font-weight: bold;
  margin-bottom: 16px;
}

.privacy-img {
  width: 184px;
}

.img-fluid {
  max-width: 100%;
  /* height: auto; */
}

.md-b {
  padding-bottom: 13px;
}

.mobile-tab {
  display: none;
  background-color: #f6f6f6;
  padding: 40px 0px;
}

/* button.accordion-button {
  background: #eaf9ff !important;
  color: #f8921f !important;
} */
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-item {
  margin: 10px 0px;
}

.View-More {
  border: none;
  background-color: #f8921f;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 18px;
  border-radius: 3px;
}


::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(246, 246, 246);
}

::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #c1c1c1;
}

.india-coverage {
  cursor: pointer;
  border: 1px solid #c1c1c1;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 2px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #727272;
}

.india-coverage:hover {
  background-color: #f8921f;
  color: #ffff;
  border: 1px solid #f8921f;
}

.scroll-bar-country-model {
  margin: 20px 0;
  overflow: auto;
  height: 216px;
  padding: 4px 7px;
  scroll-behavior: auto;
  scroll-behavior: smooth;
}

.scroll-bar-country-model::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(248, 250, 252);
}

.scroll-bar-country-model::-webkit-scrollbar-thumb {
  height: 1px !important;
  border-radius: 5px;
  background-color: #c1c1c1;
}

.country-li-flag-name-countymodel {
  list-style: none;
  padding: 10px 10px;
  text-align: center;
  margin: 8px 6px;
  transition: all 0.6s ease-in;
  cursor: pointer;
}

.country-li-flag-name-countymodel:hover {
  transform: translateY(-0.25rem);
  transition: .3s ease;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
}

.bd-flag {
  margin: 0 3px;
}

.bd-text-flag {
  margin: 0 5px;
}

.ul-country-model {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.dolor-coverage-Country {
  font-weight: 500;
  font-size: 14px;
  color: #727272;
}

.dolor-coverage-Country-homepage {
  font-weight: 500;
  font-size: 14px;
  color: #727272;
}


/* --------------------------------- */

.scrol-b-details {
  overflow: auto;
  min-height: 195px;
  border-radius: 15px;
  background-color: #fbecec;
  box-shadow: 0 0px 30px 0px rgb(2 3 8 / 13%);
  border: 11px solid white;
  height: 195px;
}

.scrol-b-details::-webkit-scrollbar {
  width: 6px;
  background-color: rgb(248, 250, 252);
}

.scrol-b-details::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #c1c1c1;
  border-radius: 10px;
}

.srcol-detail-ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 5px;
  gap: 0px 10px;
}

.scroll-detail-li {
  list-style: none;
  text-align: center;
  transition: all 0.6s ease-in;
  cursor: pointer;
}

.scroll-detail-li:hover {
  transform: translateY(-0.25rem);
  transition: .3s ease;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
}

.details-para-2 {
  font-weight: 600;
  margin-bottom: 0px;
  color: black;
  font-size: 17px;
}

/* ----------------------------------  */
.scroll-bar-network-model {
  margin: 20px 0;
  overflow: auto;
  height: 216px;
  padding: 4px 7px;
  scroll-behavior: auto;
  scroll-behavior: smooth;
}

.scroll-bar-network-model::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(248, 250, 252);
}

.scroll-bar-network-model::-webkit-scrollbar-thumb {
  height: 1px !important;
  border-radius: 5px;
  background-color: #c1c1c1;
}

.ul-network-model {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.network-li-flag-name-networkmodel {
  list-style: none;
  padding: 10px 10px;
  width: 100%;
  text-align: start;
  margin: 8px 6px;
  transition: all 0.6s ease-in;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.network-li-flag-name-networkmodel:hover {
  transform: translateY(-0.25rem);
  transition: .3s ease;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
}


/* contact us  */
.contact-f-c:focus {
  border-color: #f8921f;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #f8921f1c;
}

.contact-f-c:hover {
  border-color: #f8921f;
}

.contact-card {
  background-color: #f38f1e1f;
  border-radius: 10px;
  padding: 35px 15px;
  border-bottom: 3px solid #f8921fbf;

}

.contact-link {
  text-decoration: none;
  color: #727272;
  background-color: #f8921f0d;
  padding: 3px 3px;
  border-radius: 4px;

}

.contact-item {
  line-height: 50px;
}

.contact-link:hover {
  color: #f8921f;
}

/*Ghost Loading Plans */


#card-ab {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 300px;
  height: 354px;
  display: block;
}



.card.is-loading .image,
.card.is-loading h2,
.card.is-loading p,
.card.is-loading .p-2,
.card.is-loading .p-5 {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  -webkit-animation: 1.5s shine linear infinite;
  animation: 1.5s shine linear infinite;
}

.card.is-loading .image {
  height: 162px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 4px 4px;
  border-radius: 5px;
}

.card.is-loading .content {
  margin: 10px 15px 0px 15px;
}

.card.is-loading h2 {
  height: 41px;
}

.card.is-loading .p-2 {
  width: 120px;
  height: 30px;
  margin: 8px 0;

}

.card.is-loading p {
  height: 30px;
}

.card.is-loading .p-5 {
  width: 120px;

  margin: -11px 0;

}


@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}


.network-a {
  transition: zoom all 2s ease-in infinte;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.ribbon {
  position: absolute;
  padding: 0 0.5em;
  font-size: 13px;
  line-height: 1.875em;
  color: #fff;
  border-radius: 0 0.156em 0.156em 0;
  background: #047d94;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 14em;
  font-weight: normal;
}

.ribbon:before,
.ribbon:after {
  position: absolute;
  content: '';
  display: block;
}

.ribbon:before {
  width: 0.469em;
  height: 29.5px;
  padding: 0 0 0.438em;
  top: 0;
  left: -0.469em;
  background: inherit;
  border-radius: 0.313em 0 0 0.313em;
}

.ribbon:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}


/* new-css  */
.bghjHX {
  overflow: hidden;
  margin: 0 29px 0 29px !important;
}

/* new-css */

/* skelelon  */
.plan-sec-skelon {
  display: flex;
  position: absolute;
  top: 145px;
  left: 0;
  right: 0;
  height: 1305px;
  justify-content: center;
}

.loading-skeleton .card-title-skel-1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
#skel-img-top,
.btn-primary-skelon-3,
.card-text-left,
.col-skel-div,
.next-right-para,
.addtinl-left-skel,
.avaleble-right-skel,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}

.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton label::placeholder,
.card-skel-div-1,
#card-skel-div-2,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

#skel-img-top {
  height: 317px;

}

.card-title-skel-1 {
  padding: 16px 5px;
  border-radius: 5px;
  margin: -7px 35px 6px 40px;
}

.card-title-skel-h6 {
  padding: 10px 5px;
  border-radius: 5px;
}

.btn-primary-skelon-3 {
  transform: translate(181px, 3px);
  padding: 7px 44px;
}

.card-title-right-skel {
  margin: -10px -10px 7px -12px;
  height: 39px;
  border-radius: 5px;
  display: inline-block;
  width: 211px;
}

.card-text-right-skel {
  height: 87px;
  border-radius: 5px;
}

.btn-primary-btn-right-skel {
  width: 100%;
  margin: -10px 0;
  height: 170px;
}

.card-text-left {
  border-radius: 5px;
}

#card-skel-div-2 {
  padding-bottom: 53px;
  padding: 10px;
  border: none;
}

.card-skel-div-1 {
  padding-bottom: 130px;
  padding: 10px;
  border: none;
}

.col-skel-div {
  border-radius: 5px;
  background-color: #fff;
}

#skel-img-top-right {
  height: 162px;
  margin-bottom: 20px;
}

.card-title-right-skel-h5-right {
  margin-top: 27px;
  padding: 10px;
  border-radius: 5px;
}

.card-title-right-skel-h6-right {
  padding: 5px;
  border-radius: 5px;
}

.skel-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0px 0px 51px;
  margin: 0 0 33px 0;
}

.card-title-skel-span-right {
  padding: 6px 0 0 36px;
  border-radius: 5px;
}

.next-right-para {
  height: 150px;
  margin: 18px 0 5px 0;
  border-radius: 5px;
}

.card-title-skel-span-left {
  width: 102px;
  padding: 4px;
  border-radius: 5px;
  margin: 1px -15px 0 0px;
}

.addtinl-left-skel {
  width: 99%;
  height: 329px;
  border-radius: 5px;
  background-color: #eee !important;
  margin: 26px 0 0 34px;
}


.avaleble-right-skel {
  width: 97%;
  height: 328px;
  border-radius: 5px;
  background-color: #eee !important;
  margin: 25px 0px 0 -19px;
}

.bottom-div-panel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 43px 0px 0px 0px;
  background-color: #fff;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
    background-position: -468px 0;
  }

  to {
    opacity: 1;
    background-position: 468px 0;
  }
}



.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
  background-color: white;

}

.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}

.placeholder-glow {
  position: relative;
}

.placeholder {
  position: absolute;
  border-radius: 5px;
  padding: 25px 0px;
}

.plan-n-skel-p {
  position: absolute;
  border-radius: 5px;
  width: 380px;
  height: 60px;
}

.data-placehl {
  position: absolute;
  padding: 17px 0;
  height: 30px;
  margin: 0 -10px;
  border-radius: 5px;
}

.validity-skel-placehlder {
  position: absolute;
  padding: 17px 0;
  height: 30px;
  margin: 0 -10px;
  border-radius: 5px;
  z-index: 999;
}

.price-skel-placehlder {
  position: absolute;
  padding: 17px 0;
  margin: 0 -10px;
  border-radius: 5px;
}

.total-skelo-p {
  position: absolute;
}

.price-skelo-p {
  position: absolute;
  left: 58%;
}

.choose-payment-m-skel {
  position: absolute;
  padding: 24px 0px;
  margin-left: 14px
}

.fancy-placegolder {
  height: 170px;
}

.images-n-skel-p {
  position: absolute;
  height: 260px;
  z-index: 1;
}

.paypal-button-skel {
  position: absolute;
  padding: 32px 0px;
  width: 97%;
}

.card-detail-new {
  height: 600px;
  width: 845px;
}



.card-detail-placeholfer-skel {
  height: 600px;
}

.row-pl-skel {
  position: absolute;
  min-height: 600px;
}


.card-postion {
  position: relative;
  top: 0;
}

.popover__wrapper {
  position: absolute;
  margin-top: 1.5rem;
  display: inline-block;
  right: 26px;
  top: -10px;
}




.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 54px;
  left: -115px;
  transform: translate(0, 10px);
  background-color: #ffffff;
  padding: 7px 7px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: 237px;
  height: 90px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}



.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ede8e8 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

/* .popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
} */
.popoverShow {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  text-align: center;
}

/* .details-card-1 {
.details-card-1 {
  position: relative;
} */

.Demo__some-network__share-button {
  background-color: #fff !important;
  border: 1px solid #dad7d752 !important;
  border-radius: 100px;
  padding: 6px 6px !important;

}


.carousel-ite-1 {

  height: 580px;
}

.inner-div {
  position: absolute;
  top: 30%;
  right: 5%;
  width: 42%;
}


.ribbon-plan-Details {
  position: absolute;
  padding: 0 0.5em;
  font-size: 13px;
  line-height: 1.875em;
  color: #fff;
  border-radius: 0 0.156em 0.156em 0;
  background: #047d94;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 12em;
  font-weight: normal;
}


.ribbon-plan-Details {
  position: absolute;
  padding: 0 0.5em;
  font-size: 13px;
  line-height: 1.875em;
  color: #fff;
  border-radius: 0 0.156em 0.156em 0;
  background: #047d94;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 10em;
  font-weight: normal;
}

.ribbon-plan-Details:before,
.ribbon-plan-Details:after {
  position: absolute;
  content: '';
  display: block;
}

.ribbon-plan-Details:before {
  width: 0.469em;
  height: 29.5px;
  padding: 0 0 0.438em;
  top: 0;
  left: -0.469em;
  background: inherit;
  border-radius: 0.313em 0 0 0.313em;
}

.ribbon-plan-Details:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}

/* dteails page new design */
.i-agree-main {
  display: inline-flex;
}

.plan-data-new-design {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-more-details-disable {
  height: 40px;
  width: 100%;
  text-align: center;
}

.btn-more-details {
  background-color: #f8921f;
  color: #ffffff !important;
  border: 1px solid #f8921f;
  height: 40px;
  width: 100%;
  text-align: center;
}

.scroll-detail-li {
  list-style: none;
  text-align: center;
  transition: all 0.6s ease-in;
  cursor: pointer;
}

.top-up-package {
  text-align: start;
  color: #727289;
  font-size: 16px;
  padding: 0;
}

/* new card details  */
.plan {
  border-radius: 16px;
  box-shadow: 0 0px 30px 0px rgb(2 3 8 / 13%);
  padding: 10px;
  background-color: #ffffff;
  color: #697e91;
}

.plan strong {
  font-weight: 600;
  color: #425275;
}

.plan .inner {
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  background-color: #ffecd6b8;
  border-radius: 12px;
  position: relative;
}

.plan .inner-2 {
  align-items: center;
  padding: 12px;
  padding-top: 40px;
  background-color: #f9e4e4b8;
  border-radius: 12px;
  position: relative;
}

.plan .pricing {
  position: absolute;
  top: -12px;
  right: 0;
  background-color: #f7ba73f5;
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.2em 0.75em;
  font-size: 1rem;
  font-weight: 600;
  color: #42424e;
  cursor: pointer
}

.plan .pricing-2 {
  position: absolute;
  top: 0px;
  left: 0;
  background-color: #d14949cc;
  ;
  border-radius: 0em 1.5em 1.5em 0em;
  display: flex;
  align-items: center;
  padding: 0.2em 0.75em;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer
}

.plan .pricing small {
  color: #707a91;
  font-size: 0.75em;
  margin-left: 0.25em;
}

.plan .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: #414141;
  position: absolute;
  top: 3px;
}

.plan .title+* {
  margin-top: 0.75rem;
}

.plan .info+* {
  margin-top: 1rem;
}

.plan .features {
  display: flex;
  flex-direction: column;
}

.plan .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.plan .features li+* {
  margin-top: 0.75rem;
}

.plan .features .icon {
  background-color: #1FCAC5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.plan .features .icon-2 {
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}


.plan .features .icon svg {
  width: 14px;
  height: 14px;
}

.plan .features+* {
  margin-top: 1rem;
}

.plan .action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.plan .features .icon {
  background-color: #f88f16f5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding-left: 3p;
  margin-right: 3px;
  font-size: 13px;
}

.plan .button:hover,
.plan .button:focus {
  background-color: #4133B7;
}

ul.features {
  margin-top: 14px;
  margin-bottom: 1rem;
  padding-left: 0rem
}

.features li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature-span {
  font-size: 14px;
  color: #2c2a2a;
}

.feature-span-2 {
  font-size: 14px;
  color: #000000;
}

.icon-set {
  display: flex;
  align-items: center;
}

/* new card details  */
/* dteails page new design */



/* 
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
} */

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

/* 03/05/2024 CSS new code */
.more_plan_btn {
  text-decoration: none;
  background: #f8921f;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
}

.more_plan_btn:hover {
  color: #fff;
  background-color: #db7c10;
}

/* new css 1-2024  */
#card-ab-plan {
  width: 100%;
  position: relative;

  top: 0px;
  height: 1000px;
  padding: 20px;

}

#inner-card-one {
  width: auto;
}

.card.is-loading .image {
  height: 206px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 4px 4px;
  border-radius: 5px;
}

.card.is-loading .p-2-one {
  width: 388px;
  height: 30px;
  margin: 7px auto;
}

.card.is-loading .inner-side-one {
  height: 25px;
}

.card.is-loading .h2-tilt {
  height: 21px;
}
.supported.is-loading image{
  height: 150px ;
}

.ab-card.is-loading .inner-side-one{
  width: 75px;
}
.ab-card.is-loading .inner-side-one-w {
  width: 159px;
  height: 106px;
}
.img-bd-card.is-loading .images{
  height: 150px !important;
}

.plan-det-car .kVtMtA {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 27px;
  width: 27px;
  /* left: 0; */
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: -24px !important;
  right: 0px;
}

.plan-det-car .bhRnqO {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 27px;
  width: 27px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: inherit;
  right: -24px !important;
}

/* new css 1-2024  */