.loader-main {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000082;
    transform: translate(50% 50%);
    z-index: 9999;
    filter: blur(0px);
}


.loader {
    --dim: 3rem;
    width: var(--dim);
    height: var(--dim);
    position: relative;
    animation: spin988 2s linear infinite;
    margin: 20% auto;
}

.loader .circle {
    --color: #333;
    --dim: 1.2rem;
    width: var(--dim);
    height: var(--dim);
    background-color: #f8921f;
    border-radius: 50%;
    position: absolute;
}

.loader .circle:nth-child(1) {
    top: 0;
    left: 0;
}

.loader .circle:nth-child(2) {
    top: 0;
    right: 0;
}

.loader .circle:nth-child(3) {
    bottom: 0;
    left: 0;
}

.loader .circle:nth-child(4) {
    bottom: 0;
    right: 0;
}

@keyframes spin988 {
    0% {
        transform: scale(1) rotate(0);
    }

    20%,
    25% {
        transform: scale(1.3) rotate(90deg);
    }

    45%,
    50% {
        transform: scale(1) rotate(180deg);
    }

    70%,
    75% {
        transform: scale(1.3) rotate(270deg);
    }

    95%,
    100% {
        transform: scale(1) rotate(360deg);
    }
}