@media only screen and (min-width: 1022px) and (max-width: 1024px) {
    .india-coverage {
        cursor: pointer;
        border: 2px solid #c1c1c1;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        padding: 2px 6px;
        border-radius: 5px;
        font-size: 10px !important;
    }


    .day-5gb {
        font-size: 12px;
        font-weight: 500;
        color: #727272;
    }

    /* propover  */
    .card-postion {
        position: relative;
        top: 0;
    }

    .popover__wrapper {
        position: absolute;
        margin-top: 1.5rem;
        display: inline-block;
        top: -15px;
        right: 24px;
    }

    .popover__content {

        top: 54px;
        left: -200px !important;

    }

    /* propover  */
    /* details-page-27-12-23 */
    .plan .inner-2 {

        padding: 7px;
        padding-top: 46px;

    }

    .plan .pricing-2 {
        font-size: 0.8rem;

    }

    .feature-span-2 {
        font-size: 12px;
        color: #000000;
        white-space: nowrap;
    }

    .ribbon-plan-Details {
        top: 7em !important;
    }

    .scrol-b-details {
        width: 178px;
    }

    #plan-car-div {
        width: 180px;

        margin-left: 18px;
    }

    .ribbon {
        top: 9em !important;
    }

    /* details-page-27-12-23 */
}

@media only screen and (max-width: 1024px) {}


@media only screen and (min-width: 1024px) and (max-width: 1366px) {


    body {
        font-family: poppins;
        background-color: #fafafa;
        overflow-x: hidden;
    }



    .login-bg {
        height: 100vh;
    }

    #nav-item-h {
        padding-left: 20px;
    }

    .control-panel-h5-h {
        font-size: 17px;
    }

    .inner-div {
        padding: 0px 20px;
    }

    .col .card {
        height: auto;
        width: 100%;
    }

    .search-box {
        width: auto;
        position: relative;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 5px 0px;
    }

    .card-img-top {
        height: auto;
    }

    /* shop  */
    .text-icon {

        padding-left: 9px;
    }

    /* .search-box {
            width: auto;
            position: relative;
            display: flex;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            padding: 5px 0px;
        } */

    .autocomplete-responsive {
        position: absolute;

        top: 98%;
        left: 0px;
        max-height: 400px;
        z-index: 9999;
        display: block;
        border-radius: 5px;
        box-shadow: grey 2px 2px 2px -1px;
        padding-bottom: 10px;
    }

    /* .autocomplete-responsive-1 {
        position: absolute !important;
        width: 718px !important;
        top: 368px !important;
        left: 277.575px !important;
        max-height: 400px;
        z-index: 9999;
        display: block;
        border-radius: 5px;
        box-shadow: grey 2px 2px 2px -1px;
        padding-bottom: 10px;
    } */
    .nav-pills .nav-link {
        background: 0 0;
        border: 1px solid gray;
        border-radius: var(--bs-nav-pills-border-radius);
        font-size: 14px;
    }


    /* shop  */

    /* login  */
    .login-tab {
        margin: 0px 5px;

    }

    .resetPasswordImg {
        width: 180px;
    }

    .get-para {
        font-size: 13px;
    }

    .icon-text p {
        font-size: 12.5px;
        text-align: justify;
    }

    /* login  */
    .margin-l {
        padding-right: 80px;
    }

    .searching-heading {
        line-height: normal;
        font-size: 30px;
    }

    .sec-shop-2 {
        padding: 0px 0px;
    }

    .price-text {
        position: static;
    }

    .card-1-new {
        height: 250px;
    }


    .bghjHX {
        overflow: hidden;
        margin: 0 14px 0 21px !important;
    }

    .footer-set-m {
        margin: 0 !important;
    }

    /* propover  */

    .popover__content {
        top: 54px;
        left: -145px;
    }

    /* propover  */

    /* details-page-27-12-23 */
    .details-para-3 {
        font-size: 14px;
        white-space: nowrap;
    }

    .ribbon-plan-Details {
        top: 9em;
    }

    .ribbon {
        top: 12em;
    }

    /* details-page-27-12-23 */
}




@media only screen and (min-width: 768px) and (max-width: 1023px) {
    body {
        font-family: poppins;
        background-color: #fafafa;
        overflow-x: hidden;
    }

    .contact-item {
        font-size: 12px;
    }

    .price-text {
        position: static;
    }

    .ribbon {
        top: 14em;
    }

    .india-coverage {
        cursor: pointer;
        border: 2px solid #c1c1c1;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        padding: 2px 6px;
        border-radius: 5px;
        font-size: 13px;
    }

    .text-overlayShop {
        top: 83%;
        font-size: 14px;
    }

    .col .card {
        height: auto;
        width: 313px;
    }

    .login-bg {
        height: 100vh;
    }

    .img-contol-panel-1 img {
        width: 100%;
        height: auto;
        padding: 11px 10px 8px 10px;
    }

    .get-para {
        font-size: 10px;
    }

    .icon-text p {
        font-size: 11.5px;
        text-align: justify;
    }

    .modal-content1 {
        width: 100%;
        margin: 0;
    }

    .order-details img {
        width: 60%;
    }

    .order-yet {
        padding: 20px 15px 0px 15px !important;
        /* margin: 0px 0px 0px -52px; */
    }

    .gm-p {

        margin-left: 0px;
    }

    .sec-2 {

        padding: 25px 0px !important;
    }

    #nav-item-h {
        padding-left: 0px;
    }

    .heading-1 {
        padding: 5px 0px;
    }

    .traveling-title {
        font-weight: 700;
    }

    .sec-3 {
        padding: 25px 0px !important;
    }

    .m-order {
        order: 2;
    }

    .text-shop-icons {
        padding-left: 26px !important;
    }

    .inner-div {
        padding: 20px 20px;
    }

    .search-box {
        width: 100%;
        position: relative;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 5px 0px;
    }

    .heading-1 {
        text-align: center;
        font-weight: 600;
        line-height: 36px;
        font-size: 19px;
        padding: 5px 0px;
    }

    .para-1 {
        font-size: 14px;
    }

    .nav-item {
        padding-left: 0px;
    }

    /* home  */
    .text-icon {
        padding-left: 0px;
    }

    .fa-star,
    .fa-flag {
        color: goldenrod;
        font-size: 11px;
    }

    .header-name-text {
        font-size: 12px;
    }

    ol,
    ul {
        padding-left: 0rem;
    }

    /* home  */

    /* shop  */
    .nav-item {
        padding-right: 13px;
    }

    .shop-1 {
        margin-left: -26px;
    }

    .card-img-top {
        height: auto !important;
    }

    .text-overlayPlanDetails {
        bottom: 3%;
    }

    /* shop  */

    /* partner  */
    .become-btn {

        padding: 10px 39px;

    }

    .create-sim-para {
        padding: 12px 0px 15px 0px;

    }

    .jion-our-title {
        padding: 17px 37px 18px 20px;

    }

    .zim-cover-para {
        line-height: 26px;
        padding: 0px 19px 0px 22px;
        font-size: 17px;

    }

    .img-contol-panel {
        padding: 25px 0px 0px 5px;
    }

    .card-1-new {
        height: 270px;
        padding: 0px 5px;
        /* margin: 12px; */

    }

    /* partner  */

    /* model  */
    .modal-dialog {
        max-width: 582px;
        margin-right: auto;
        margin-left: auto;
    }

    /* model  */

    /* plan-details  */
    .details-card {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        border-radius: 10px;
        background-color: #f44b57;
        padding: 20px 20px;
    }



    .addtional-main-div {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        border-radius: 5px;
        /* background-color: #ffffff; */
        padding: 10px 10px;
    }

    /* plan-details  */

    .modal-content1 {
        width: 100%;
    }

    .modal-content2 {
        width: 100%;
        margin: 0 !important;
    }

    .modal-content2 {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100% !important;
        margin: 0px !important;
        color: var(--bs-modal-color);
        pointer-events: auto;
        background-color: #f5f5f5;
        background-clip: padding-box;
        border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
        border-radius: var(--bs-modal-border-radius);
        outline: 0;
        border-bottom: 6px solid #f8921f;
    }

    .autocomplete-responsive {
        position: absolute;

        top: 98%;
        left: 0px;
        max-height: 400px;
        z-index: 9999;
        display: block;
        border-radius: 5px;
        box-shadow: grey 2px 2px 2px -1px;
        padding-bottom: 10px;
    }

    /* .autocomplete-responsive-1 {
        position: absolute !important;
        width: 392px !important;
        top: 468px !important;
        left: 188.575px !important;
        max-height: 400px;
        z-index: 9999;
        display: block;
        border-radius: 5px;
        box-shadow: grey 2px 2px 2px -1px;
        padding-bottom: 10px;
    } */



    /* login  */
    .login-tab {
        margin: 0px 5px;

    }

    /* login  */

    /* partner  */
    .img-material {
        padding: 68px 39px;
    }

    .control-panel-h5 {
        font-size: 17px;
    }

    .our-pyment {
        font-size: 13.5px;
    }

    .md-b {
        padding-bottom: 13px;
    }

    /* .mt-left {
        margin-left: 12px;
    } */

    .control-panel-h5 {
        padding-left: 0px;
        color: #f8921f;
        font-weight: 600;
        font-size: 16px;
    }

    .control-panel-h5-h {
        font-size: 16px;
    }


    .dolor-coverage-Country-homepage {
        font-weight: 500;
        font-size: 13px;
        color: #727272;
    }

    .text-overlayShop {
        display: none;
    }

    .searching-heading {
        line-height: normal;
    }

    .sec-shop-2 {
        padding: 0px 0px;
    }

    /* partner  */

    .network {

        margin: 0 0 6px 0;
    }

    .footer-set-m {
        margin: 0 !important;
    }

    .carousel-ite-1 {
        height: 580px;
    }

    .carousel-ite-1 img {
        height: 580px;
    }

    /* propover  */
    .card-postion {
        position: relative;
        top: 0;
    }

    .popover__wrapper {
        position: absolute;
        margin-top: 1.5rem;
        display: inline-block;
        top: -21px;
        right: 22px;
    }

    .popover__content {

        top: 54px;
        left: -230px;

    }

    /* propover  */

    /* detail-plan  */
    .plan-sec-2 {
        background-color: #f8921f3b;
        padding: 50px 5px;
        height: auto;
    }

    .inner-div {
        position: absolute;
        top: 18%;
        right: 5%;
    }

    .pro-sticky {
        position: static;

    }

    /* detail-plan  */

    /* detail-page-27-12-23 */
    .cov-list {
        padding: 0 15px;
    }

    .cards {
        padding: 0 15px;
    }

    /* #details-ps-3 {
        margin-top: 24px;
    } */

    .ribbon-plan-Details {
        top: 14em !important;
    }

    /* detail-page-27-12-23 */
}

@media only screen and (max-device-width : 360px) {
    .ribbon-plan-Details {
        top: 9em !important;
    }

    .plan .pricing {
        font-size: 0.8rem;
    }

    .plan .title {
        font-size: 0.8rem;
    }
}

/* new media query  */
@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    body {
        font-family: poppins;
        background-color: #fafafa;
        overflow-x: hidden;
    }

    .contact-item {
        font-size: 12px;
    }

    .sec-shop-3 {
        display: none;
    }

    .col .card {
        height: auto;
    }

    .mobile-tab {
        display: block;
    }

    div#parent img {
        height: auto;
    }

    div#parent-13 img.img-fluid.rounded.start-height {
        display: none;
    }

    .card-img-top {
        height: auto !important;
    }

    .searching-heading {
        line-height: 30px;
        font-size: 22px;
    }

    .sec-shop-2 {
        padding: 0px 0px;
    }

    .gm-p {
        margin-top: 30px;
        margin-left: 0px;
    }

    .login-bg {
        height: 100vh;
    }

    .modal-content1 {
        width: 100%;
        margin: 0;
    }

    .pro-status {
        font-size: 10px;
    }

    .img-flag-order img {
        width: 50px;
        margin-right: 10px;
    }

    .plan-name {
        font-size: 14px;
    }

    .price {
        font-size: 14px;
    }

    .validity {
        font-size: 14px;
    }

    #nav-item-h {
        padding-left: 40px;
    }

    .sec-2 {
        padding: 25px !important;
    }

    .order-details img {
        width: 60%;
    }

    .order-yet {
        padding: 20px 15px 0px 15px !important;
        /* margin: 0px 0px 0px -52px; */
    }

    .custom-title-class {
        font-size: 1.2em !important;
    }

    .mbm {
        margin-bottom: 1em;
    }

    .m-order {
        order: 2;
    }

    .navbar-brand {
        padding-left: 10px;
    }

    a.navbar-brand img {
        width: 180px;
    }

    .become-btn {
        padding: 7px 30px;
    }

    .rounded-start {
        width: 60%;
    }

    .modal-body {
        padding: 0px 10px 0px 10px !important;
    }

    .antdInput {
        padding: 5px 15px;
    }

    .contact-sale h5 {
        font-size: 20px;
    }

    .inner-div {
        padding: 10px 10px;
    }

    .sec-1 {
        padding: 80px 0px 80px 0px;
    }

    .traveling-para {
        font-size: 16px;
    }

    .traveling-title {
        font-weight: 700;
        font-size: 20px;
    }

    .search-box {
        width: 100%;
        position: relative;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 1px 0px;
    }

    .para-1 {
        font-size: 12px;
    }

    .heading-1 {
        text-align: center;
        color: #000;
        font-weight: 600;
        line-height: 21px;
        font-size: 18px;
        padding: 5px 0px;
    }

    .india-coverage {
        font-size: 13px;
        padding: 2px 5px;
    }

    .heading-3 {
        font-size: 25px
    }

    .text-footer ul li {
        display: inline-block;
        padding: 0px 6px;
    }

    .most-text {
        font-size: 14px;
    }

    ol,
    ul {
        padding-left: 0rem;
    }

    .sm-order-1 {
        order: 2;
    }


    .rounded-start {
        width: 30%;
    }

    .card-body {
        padding: 10px;
    }

    .get-para {
        padding-left: 0px;
        font-size: 11px;
    }

    .padding-size {
        padding: 2px 0px;
    }



    .icon-text p {
        font-size: 13px !important;
        text-align: justify;
    }

    /* 
.btn-more-divices {
    text-decoration: none;
    padding: 7px 36px;
    border: none;
    outline: none;
    color: #ffffff;
    transition: all 0.6s;
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.20), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.10), 0 16px 16px rgba(0,0,0,0.05);
    background: #f8921f;
} */

    .heading-carousel-emap {
        font-size: 20px;
    }

    .para-carousel-emap {
        font-size: 16px;
    }

    .fa-star,
    .fa-flag {
        color: #eba306;
        font-size: 10px;
    }

    .sec-3 {
        background-color: #fff;
        padding: 25px !important;
    }

    .btn-more-product {
        text-decoration: none;
        padding: 0px 0px;
        outline: none;
        color: #f89829 !important;
        font-weight: 500;
        position: relative;
        transition: all .35s;
        border-radius: 5px;
        font-size: 15px;
    }

    .reg-heading {
        margin-left: 0;
        font-size: 16px;
    }

    .price-text {
        position: static;
    }

    .btn-more-product:hover {
        background-color: #f8921f;
        color: #ffffff !important;
        border: 1px solid #f8921f;
    }



    .btn-more-divices {
        text-decoration: none;
        padding: 7px 53px !important;
        border: none;
        outline: none;
        color: #2d2a2a !important;
        font-weight: 600;
        border: 1px solid #727272;
        transition: all 0.6s;
        /* box-shadow: 0 1px 1px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.20), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.10), 0 16px 16px rgba(0,0,0,0.05); */
        background: #f8fafb;
        border-radius: 5px;
        transition: all 0.5;
    }

    .btn-more-divices:hover {
        color: #ffffff !important;
        background: #f8921f;
        border: 1px solid #f8921f;


    }

    .text-footer {
        margin-bottom: 15px;
        text-align: center;
    }

    .text-icon {
        padding-left: 10px;
    }

    /* shop  */
    .nav-item {
        padding-right: 13px;
    }

    .search-destination {
        padding: 0px 0px;
    }

    .nav-link-btn {
        margin: 10px 0px;
    }

    .text-shop-icons {
        padding-left: 35px !important;
    }

    .keepgo-h {
        padding: 37px 15px;
    }

    .img-material {
        padding: 46px 23px;

    }

    .sec-patner-2 {

        padding: 30px 4px;
    }

    .card-icon {
        margin-bottom: 24px;
        transition: all 0.3s ease-out;
        height: auto;
    }

    .card-1-new {
        height: auto;
    }

    .jion-our-title {
        padding: 17px 18px 18px 20px;
        line-height: 35px;

    }

    .zim-cover-para {

        padding: 2px 18px 0px 22px;
        font-size: 19px;

    }

    .sec-patner {

        margin-bottom: 16px;
    }

    .mt-top {
        margin-top: 45px;
    }

    .owl-nav .owl-next {

        position: absolute;
        top: calc(50% - 57px) !important;
        right: -8px !important;
        opacity: 1;
        font-size: 56px !important;
        z-index: 1;

    }

    .owl-nav .owl-prev {
        position: absolute;
        top: calc(50% - 57px) !important;
        left: -11px !important;
        opacity: 1;
        font-size: 56px !important;
        z-index: 1;
    }

    /* shop  */

    /* contact  */
    .text-message {
        padding: 11px 11px 9px 0px;
    }

    /* contact  */

    /* login  */
    .login-tab {
        margin: 0px 5px;

    }

    .mob-gap-login {
        padding: 0px 10px;
    }

    /* login  */

    #position-absolute {
        position: absolute;
        right: -6px;
        top: -15px;
        padding: 2px 12px;
        background-color: red;
        color: white;
        border-radius: 100%;
        font-size: 17px;
        font-weight: bold;
    }

    /* partner  */
    .img-contol-panel-1 img {
        width: 60%;
        height: auto;

    }

    /* partner  */

    /* check-out  */
    .total-add-w {
        width: 50%;
    }

    .usd-add-w {
        width: 50%;
    }

    .text-center {
        text-align: center !important;
        margin-bottom: 10px;
    }

    .gift-card {
        margin-bottom: 10px;
    }

    /* check-out */

    /* details  */
    .carousel-control-next {
        right: -31px !important;
    }

    .carousel-control-prev {
        left: -27px !important;
    }



    .autocomplete-responsive {
        position: absolute;
        width: 100%;
        top: 98%;
        left: 0px;
        max-height: 400px;
        z-index: 99;
        display: block;
        border-radius: 5px;
        box-shadow: grey 2px 2px 2px -1px;
        padding-bottom: 10px;
    }

    /* details  */

    .modal-content1 {
        width: 100%;
    }

    .modal-content2 {
        width: 100%;
        margin: 0 !important;
    }

    .noPlanFoundImg {
        width: 300px;
        height: auto;
    }

    .noPlanFoundImg img {
        width: 100%;
        height: auto;
    }

    .search-input {
        padding: 10px 45px 10px 25px;
    }

    .control-panel-h5 {
        font-size: 15px;
    }

    .control-panel-h5-h {
        font-size: 15px;
    }

    .text-overlayShop {
        display: none;
    }

    .day-5gb {
        font-size: 13px;
        font-weight: 500;
        color: #000;
    }

    .dolor-coverage-Country-homepage {
        font-weight: 500;
        font-size: 13px;
        color: #727272;
    }

    .ribbon {
        top: 9em;
    }

    .dolor {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        white-space: nowrap;
    }

    .footer-set-m {
        margin: 0 !important;
    }

    /* carousel-home-page  */
    .carousel-ite-1 {
        height: 300px;

    }

    .carousel-ite-1 img {
        height: 100%;

    }

    .inner-div {
        position: absolute;
        width: 77%;
        position: absolute;
        top: 89px;
        right: 15px;
    }

    .card-postion {
        position: relative;
        top: 0;
    }

    .popover__wrapper {
        position: absolute;
        margin-top: 1.5rem;
        display: inline-block;
        top: -21px;
        right: 12px;
    }

    .popover__content {

        top: 54px;
        left: -230px;

    }


    /* carousel-home-page  */

    /* details css  */
    .plan-sec-2 {
        background-color: #f8921f3b;
        padding: 50px 5px;
        /* min-height: 2437px;
        max-height: 2500px; */
        height: calc(100% - 100px);
    }


    .pro-sticky {
        position: static;

    }

    .p-set {
        padding-top: 23px;
        font-size: 12px;
        font-weight: 500;
        color: #151414;
        white-space: nowrap;
    }

    /* details css  */
    /* details-page-27-12-23 */
    .cov-list {
        padding: 0 15px;
    }

    .cards {
        padding: 0 15px;
    }

    #details-ps-3 {
        margin-top: 24px;
    }

    .ribbon-plan-Details {
        top: 12em;
    }
}

/* new media query  */



@media only screen and (min-device-width : 0px) and (max-device-width : 319px) {
    body {
        font-family: poppins;
        background-color: #fafafa;
        overflow-x: hidden;
    }

    .ribbon {
        top: 1em;
    }

    .text-overlayShop {
        display: none;
    }

    .text-message p {
        font-size: 14px;
    }

    .contact-item {
        font-size: 10px;
    }

    .price-text {
        position: static;
    }

    .col .card {
        height: auto;
    }

    .sec-shop-2 {
        padding: 0px 0px;
    }

    .search-input {
        padding: 10px 45px 10px 25px;
    }

    .card-img-top {
        height: auto !important;
    }

    .sec-2 {
        padding: 15px !important;
    }

    #nav-item-h {
        padding-left: 40px;
    }

    .inner-div {
        padding: 10px 10px;
    }

    .order-details img {
        width: 60%;
    }

    .sec-1 {
        padding: 80px 0px 80px 0px;
    }

    .order-yet {
        padding: 20px 15px 0px 15px !important;
        /* margin: 0px 0px 0px -52px; */
    }

    .traveling-para {
        font-size: 12px;
    }

    .traveling-title {
        font-weight: 700;
        font-size: 20px;
    }

    .search-box {
        width: 100%;
        position: relative;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 5px 0px;
    }

    .text-icon {
        padding-left: 0px;
    }

    .gm-p {
        margin-top: 30px;
        margin-left: 0px;
    }

    .price {
        font-size: 14px;
    }

    .text-overlayShop {
        top: 82%;
        font-size: 14px;
    }

    div#parent-13 img.img-fluid.rounded.start-height {
        display: none;
    }

    .validity {
        font-size: 14px;
    }

    .plan-name {
        font-size: 14px;
    }

    .img-flag-order img {
        width: 70px;
        height: 60px;
    }

    .modal-content1 {
        width: 100%;
        margin: 0;
    }

    .contact-sale h5 {
        font-size: 18px;
    }

    .navbar-brand {
        padding-left: 10px;
    }

    a.navbar-brand img {
        width: 150px;
    }

    .text-footer ul li {
        font-size: 14px;
    }

    .para-footer-all {
        font-size: 14px;
    }

    .nav-pills .btn-account-f.active,
    .nav-pills .show>.nav-link {
        font-size: 15px;
    }

    div#parent img {
        height: auto;
    }

    .modal-body {
        padding: 0px 10px 10px 10px !important;
    }

    .m-order {
        order: 2;
    }

    .mbm {
        margin-bottom: 1em;
    }

    .icon-text p {
        font-size: 10px;
        text-align: justify;
    }

    .img-contol-panel {
        padding: 15px 32px 1px 44px;
    }

    .get-para {
        padding-left: 0px;
        font-size: 9px;
    }

    .img-fluid {
        height: auto;
    }

    .control-panel-h5-h {
        font-size: 14px;
    }

    .control-panel-h5 {
        font-size: 14px;
    }

    .sec-shop-3 {
        display: none;
    }

    .mobile-tab {
        display: block;
    }

    .heading-1 {
        text-align: center;
        color: gray;
        font-weight: 600;
        line-height: 36px;
        font-size: 19px;
        padding: 5px 0px;
    }

    .traveling {
        padding: 0px 0px;
    }



    .heading-3 {
        font-size: 19px;
    }

    .btn-more-product {
        text-decoration: none;
        padding: 7px 42px;
        border: none;
        outline: none;
        color: #2d2a2a !important;
        font-weight: 600;
        border: 1px solid #727272;
        transition: all 0.6s;
        /* box-shadow: 0 1px 1px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.20), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.10), 0 16px 16px rgba(0,0,0,0.05); */
        background: #f8fafb;
        border-radius: 5px;
        transition: all 0.5;
    }

    .btn-more-product:hover {
        background-color: #f8921f;
        color: #ffffff !important;
        border: 1px solid #f8921f;
    }

    .divices {
        padding: 14px 15px;
        text-align: -webkit-match-parent;
    }

    .text-footer {
        margin-bottom: 15px;
        text-align: center;
    }


    .heading-2 {
        text-align: start;
        color: #f8921f;
        font-weight: 600;
        padding-top: 14px;
        font-size: 19px;
    }

    .header-carousel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 3px;
    }

    .header-name-text {
        font-size: 12px;
        white-space: nowrap;
    }

    .fa-star,
    .fa-flag {

        color: #eba306;
        font-size: 11px;
    }

    .heading-carousel-emap {
        font-size: 18px;
        font-weight: 500px;
    }

    .para-carousel-emap {
        font-size: 14px;
    }

    .header-fab-icon {
        font-size: 12px;
    }

    .btn-more-product {
        text-decoration: none;
        padding: 0px 0px;
        outline: none;
        color: #f89829 !important;
        font-weight: 500;
        position: relative;
        transition: all .35s;
        border-radius: 5px;
        font-size: 12px;
        border: none;
    }

    .reg-heading {
        margin-left: 0px;
        font-size: 14px;
    }

    .india-coverage {
        padding: 2px 5px;
        font-size: 12px;
    }

    .btn-more-product:hover {
        background-color: #f8921f;
        color: #ffffff !important;
        border: 1px solid #f8921f;
    }

    .dolor-coverage-Country-homepage {
        font-size: 11px;
    }

    .heading-1 {
        text-align: center;
        color: #000;
        font-weight: 600;
        line-height: 21px;
        font-size: 15px;
        padding: 5px 0px;
    }

    .nav-link-btn {
        margin: 3px 0px;
    }

    .nav-item {
        padding-right: 8px;
    }

    .search-destination {
        padding: 0px 0px;
    }

    .para-1 {
        font-size: 12px;
    }

    .sm-order-1 {
        order: 2;
    }

    /* contact us  */

    .text-message {
        padding: 11px 11px 9px 0px;
    }

    .text-footer ul li {
        display: inline-block;
        padding: 0px 0px;
    }

    ol,
    ul {
        padding-left: 0;
    }

    /* contact us  */

    /* login  */
    .login-tab {
        margin: 0px 5px;
    }

    .mob-gap-login {
        padding: 0px 10px;
    }

    /* login  */

    /* plan-detail  */
    #position-absolute {
        position: absolute;
        right: -6px;
        top: -15px;
        padding: 2px 12px;
        background-color: red;
        color: white;
        border-radius: 100%;
        font-size: 17px;
        font-weight: bold;
    }

    /* plan-detail */

    /* patner  */
    .keepgo-h {
        padding: 46px 16px;
    }

    .create-sim-para {
        padding: 12px 6px 15px 0px;
    }

    .become-btn {
        text-decoration: none;
        padding: 10px 15px;
        background: rgb(255, 255, 255);
        color: #353333;
        border: 1px solid #f8921f;
        border-radius: 5px;
    }

    .img-material {
        padding: 60px 27px;
    }


    .card-1-new {
        padding: 9px 0px;
        height: auto;
    }

    .card-icon {
        height: auto;
        margin-bottom: 10px;

    }

    .jion-our-title {
        padding: 14px 3px 18px 20px;

    }



    .zim-cover-para {

        padding: 2px 7px 22px 13px;

    }

    .img-contol-panel-1 img {

        padding: 3px 0px 2px 23px;
    }

    /* patner  */

    .model-btn-send {
        padding: 6px 79px;

    }

    /* check-out  */
    .gift-card {
        margin-bottom: 10px;
    }

    .text-center {
        text-align: center !important;
        margin-bottom: 10px;
    }

    .total-add-w {
        width: 50%;
    }

    .usd-add-w {
        width: 50%;
    }


    /* details  */
    .carousel-control-next {
        right: -21px !important;
    }

    .carousel-control-prev {
        left: -21px !important;
    }

    /* details  */



    .autocomplete-responsive {
        position: absolute;

        top: 98%;
        left: 0px;
        max-height: 400px;
        z-index: 9999;
        display: block;
        border-radius: 5px;
        box-shadow: grey 2px 2px 2px -1px;
        padding-bottom: 10px;
    }

    .modal-content2 {
        position: relative;
        display: flex;
        flex-direction: column;
        width: auto !important;
        margin: 0px !important;
        color: var(--bs-modal-color);
        pointer-events: auto;
        background-color: #f5f5f5;
        background-clip: padding-box;
        border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
        border-radius: var(--bs-modal-border-radius);
        outline: 0;
        border-bottom: 6px solid #f8921f;
    }

    .noPlanFoundImg {
        width: 300px;
        height: auto;
    }

    .noPlanFoundImg img {
        width: 100%;
        height: auto;
    }

    .forgetPasswordImg {
        width: 250px;
    }

    .dolor-coverage-Country {
        font-weight: 500;
        font-size: 11px;
        color: #727272;
    }

    .sec-3 {
        padding: 15px !important;
    }

    .day-5gb {
        font-size: 11px;
    }

    .dolor {
        font-size: 12px;
    }

    .searching-heading {
        font-size: 19px;
        line-height: 23px;
    }

    #logo-fold-size {
        width: 200px;
    }


    #imeiNumber {
        display: flex;
        flex-wrap: wrap;
    }

    .antdInputPassword {
        padding: 11px 7px;
        margin: 0 0 10px 0;
    }

    #btn-fold {
        font-weight: 600;
        margin-left: 0px !important;
        padding: 0px 30px;
        height: 45px;
        width: 100%;

    }

    .footer-set-m {
        margin: 0 !important;
    }

    .carousel-ite-1 {
        height: 300px;

    }

    .carousel-ite-1 img {
        height: 100%;

    }

    .inner-div {
        position: absolute;
        width: 77%;
        position: absolute;
        top: 89px;
        right: 15px;
    }

    .card-postion {
        position: relative;
        top: 0;
    }

    .popover__wrapper {
        position: absolute;
        margin-top: 1.5rem;
        display: inline-block;
        top: -23px;
        right: 12px;
    }

    .popover__content {
        top: 54px;
        left: -209px;
    }

    /* home page reason css  */
    .bghjHX {
        margin: 0 !important;
    }

    /* home page reason css  */


    .pro-sticky {
        position: static;

    }

    .mark,
    mark {
        padding: 0.1875em;
        background-color: var(--bs-highlight-bg);
        font-size: 12px;
    }

    .details-para-3 {
        font-size: 14px;
    }

    .p-set {

        font-size: 11px;

    }

    .p-set-1 {

        font-size: 11px;

    }

    .plan-type {

        font-size: 12px;
    }

    .dta-pack {

        font-size: 12px;
    }

    /* details-page-27-12-23 */
    .cov-list {
        padding: 0 15px;
    }

    .cards {
        padding: 0 15px;
    }

    #details-ps-3 {
        margin-top: 24px;
    }

    .plan .inner {
        align-items: center;
        padding: 8px;

    }

    .plan .pricing {
        position: absolute;
        top: -12px;
        font-size: 12px;

    }

    .plan .title {
        font-weight: 600;
        font-size: 12px;

    }

    .feature-span {
        font-size: 12px;
        color: #2c2a2a;
    }

    .plan .features+* {
        margin-top: 1rem;
        font-size: 13px;
    }

    ul.features {
        margin-top: 24px;
        margin-bottom: 1rem;
        padding-left: 0rem;
    }

    .ribbon-plan-Details {
        top: 7em !important;
    }

    .form-check-label {

        font-size: 10px;

    }

    /* details-page-27-12-23 */
}