.ant-collapse-item {
   font-size: 19px;
   border-bottom: 1px solid #d9d9d9;
   background-color: #e9e9e9;
   font-family: 'Poppins';
   font-weight: 600;
   margin-bottom: 10px;
   border-radius: 6px;
}

.ant-collapse-header-text h6 {
   font-size: 17px;
   font-weight: 600;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-collapse {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   color: rgba(0, 0, 0, 0.88);
   font-size: 14px;
   line-height: 1.5714285714285714;
   list-style: none;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
   background-color: rgba(0, 0, 0, 0.02);
   border: none;
   border-bottom: 0;
   border-radius: 8px;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-collapse>.ant-collapse-item:last-child,
:where(.css-dev-only-do-not-override-2i2tap).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
   border-radius: 6px 6px 6px 6px;
}

.ant-collapse-header-text {
   transform: translate(10px, 10px);
}

.ant-collapse-content-box {
   display: none;
}

.card-detail-new {
   width: 100%;
   height: auto;
   padding: 20px 20px 50px 20px;
   background-color: #fff;
   box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
   align-items: center;
   justify-content: space-between;
   border-radius: 10px;
}

.card-detail-new-paypal {
   width: 100%;
   height: auto;
   min-height: 600px;
   padding: 20px 20px 50px 20px;
   background-color: #fff;
   box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
   align-items: center;
   justify-content: space-between;
   border-radius: 10px;
}

.right-side-new {
   display: flex;
   align-items: center;
   justify-content: center;
}

.right-side-bar-new {
   width: 100%;
   height: 500px;
   background-color: #fff;
   box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
   border-radius: 10px;
   padding: 10px 25px;
}

.card-main-new {
   align-items: center;
   width: auto;
   /* border: 1px solid black; */
   /* display: flex;
    justify-content: space-evenly; */
   /* border: 1px solid black; */
}

.card-detail-img-new {
   width: 100%;
   height: auto;

}

.card-detail-name-new {
   margin-left: 20px;
}

.card-detail-name-new p {
   font-size: 19px;
   font-weight: 500;
   color: black;
   padding-top: 13px;
}

.title-head-new {
   margin: 8px 0 20px 0;
   font-weight: 600;
   font-size: 19px;
}


.card-detail-price-new {
   width: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;

}

.total-span-new {
   font-size: 17px;
   font-weight: 500;
   background-color: #f8931f17;
   padding: 2px 35px;
   border-radius: 5px;
}

.dolar-new-span {
   font-size: 17px;
   font-weight: 500;
}

.car-plan-detal-new {
   width: 100%;
   height: auto;
   box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
   background-color: #fff;
   border-radius: 5px;
}

.header-new {
   background-color: #e1e1e138;
   padding: 8px 10px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-radius: 3px;
   margin: 3px 3px;
}

.total-amout-new {
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-color: #e1e1e138;
   padding: 8px 10px;
   border-radius: 3px;
   margin: 14px 3px 3px 3px;
}




.apply-copun-new {
   font-size: 18px;
   width: 100%;
   height: 40px;
   border: none;
   background-color: #f8921f;
   border-radius: 4px;
   color: white;
   font-weight: 500;
}

.payment-new {
   padding: 20px 0px 0px 0px;
}

.payment-tilte {
   font-size: 18px;
   font-weight: 500;
}

/* .copun-total-amout-new{
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.copun-total-amout-text-new{
   font-size: 17px;
   font-weight: 500;
   color: #4a4a4a;
}

.copun-total-amout-num-new{
   font-size: 17px;
   font-weight: 500;
   color: #4a4a4a;
} */

.fancy-new-card {
   width: 80%;
   box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
   border-radius: 6px;
   padding: 5px 5px;
}

.ttt-p {
   display: flex;
   align-items: center;
   justify-content: space-between;

   border-top: 1px solid #adadad24;
   border-bottom: 1px solid #adadad24;
   padding: 15px 15px;
}

.order-summary-new {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 15px 15px;
   color: #4a4a4a;
   font-weight: 500;
}

.new-check {
   width: 1.3em;
   height: 1.3em;
   margin-right: 10px;
   cursor: pointer;
}

.form-check-new {
   text-align: left;

   padding: 5px 25px !important;
}


.form-check-label-new {
   display: block;
   padding-right: 10px;
   color: #727272;
   font-size: 18px;
   font-weight: 600;
}

.complte-order-new {
   border: none;
   width: 40%;
   height: 47px;
   border-radius: 5px;
   background: #f8921f;
   color: #fff;
   font-weight: 500;
   font-size: 17px;
}

.data-text-new {
   color: #4a4a4a;
}

.data-num-new {
   color: #4a4a4a;
   font-weight: 500;
}

.plan-name-new {
   font-size: 17px;
   font-weight: 500;
   color: #4a4a4a;
}

.gift-card-new:focus {
   background-color: #f8921f2b;
}

.form-check-input:focus {
   border-color: none;
   outline: none;
   box-shadow: none;
}

.form-check-input:checked {
   background-color: #f8921f;
   border-color: #f8921f;
}

.paypal-p-main-div {
   width: 62%;
   text-align: center;
   margin: 13px auto;
   position: relative;

}

.ant-btn-default:disabled {
   cursor: not-allowed;
   border-color: #d9d9d9;
   color: #fff;
   background-color: #f38e1aa3;
   box-shadow: none;
}

.ant-btn-default:not(:disabled):hover {
   color: #eaeaea;
   border-color: #ffffff;
}

.paypal-loader {
   width: 42px;
   height: 42px;
   border: 5px solid #bababaa3;
   border-bottom-color: transparent;
   border-radius: 50%;
   display: inline-block;
   box-sizing: border-box;
   animation: rotation 1s linear infinite;
   position: absolute;
   top: 2px;
   left: 50%;
   right: 0;
}

@keyframes rotation {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

@media only screen and (min-width: 1022px) and (max-width: 1024px) {
   .ribbon-header {
      font-size: 14px !important;
      padding: 0.6em 1em !important;
      margin: 0em auto 0.4em !important;
      font-family: 'poppins';
      white-space: nowrap;
   }


}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
   .card-main-new {
      align-items: center;
      width: 100%;

   }

   .card-detail-price-new {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 0px;
   }

   .fancy-new-card {
      width: 100%;
      box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
      border-radius: 6px;
   }

   .total-amout-new {

      margin: 10px 3px 3px 3px;
   }

   .ribbon-header {

      margin: 0em auto 0.7em !important;

   }

}


@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
   .card-main-new {
      align-items: center;
      width: 100%;

   }

   .card-detail-price-new {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 15px;
   }

   .complte-order-new {
      border: none;
      width: 100%;
      height: 40px;
      border-radius: 5px;
      background: #f8921f;
      color: #fff;
      font-weight: 500;
      font-size: 17px;
   }

   .fancy-new-card {
      width: 100%;
      box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
      border-radius: 6px;
   }

   .ribbon-header {
      font-size: 14px !important;

   }


}

@media only screen and (min-device-width : 0px) and (max-device-width : 319px) {
   .card-main-new {
      align-items: center;
      width: 100%;

   }

   .card-detail-price-new {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 15px;
   }

   .complte-order-new {
      border: none;
      width: 100%;
      height: 40px;
      border-radius: 5px;
      background: #f8921f;
      color: #fff;
      font-weight: 500;
      font-size: 17px;
   }

   .fancy-new-card {
      width: 100%;
      box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
      border-radius: 6px;
   }

   .form-check-new {
      text-align: left;
      padding: 5px 18px !important;
   }

   .form-check-label-new {
      display: block;
      padding-right: 10px;
      color: #727272;
      font-size: 13px;
   }

}

/* rebben header  */
.ribbon-header {
   font-size: 16px;


   width: 100%;

   position: relative;
   background: #5a5957;
   color: #fff;
   text-align: center;
   padding: 0.6em 2em;
   margin: 0em auto 2em;
   font-family: 'poppins';
   font-weight: 400;

}

.ribbon-header:before,
.ribbon-header:after {
   content: "";
   position: absolute;
   display: block;
   bottom: -1em;
   border: 1.5em solid #986794;
   z-index: -1;
}

.ribbon-header:before {
   left: -2em;
   border-right-width: 1.5em;
   border-left-color: transparent;
}

.ribbon-header:after {
   right: -2em;
   border-left-width: 1.5em;
   border-right-color: transparent;
}

.ribbon-header .ribbon-content:before,
.ribbon-header .ribbon-content:after {
   content: "";
   position: absolute;
   display: block;
   border-style: solid;
   border-color: #363536 transparent transparent transparent;
   bottom: -1em;
}

.ribbon-header .ribbon-content:before {
   left: 0;
   border-width: 1em 0 0 1em;
}

.ribbon-header .ribbon-content:after {
   right: 0;
   border-width: 1em 1em 0 0;
}
.ant-popover.ant-popconfirm {
   inset: 320.75px auto auto 635.05682px !important;
}
button.ant-btn.css-dev-only-do-not-override-1vr7spz.ant-btn-primary.ant-btn-sm {
   background:#f69322;
}
.ant-tooltip-open{
   z-index: 999999999 !important;
   display:  block !important;
}
/* rebben header  */